import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import UpdateArticle from './UpdateArticle/UpdateArticle';

export default function SuppArticle() {


    const [showUpdate, setShowUpdate] = useState(false);
    const [articleUpdate, setArticleUpdate] = useState('');


    const [articles, setArticles] = useState([]);
    const [articleDelete, setArticleDelete] = useState('');
    const [show, setShow] = useState(false);

    const tokenFromStorage = localStorage.getItem('token');
    let config = {};

    if (tokenFromStorage) {

        config.headers = {
            Authorization: 'Bearer ' + tokenFromStorage,
            'Content-Type': 'multipart/form-data'
        }
    }

    const handleClose = () => {

        setArticleDelete('');
        setShow(false);
    };

    const handleShow = (article) => {

        setArticleDelete(article);
        setShow(true);
    };

    useEffect(() => {

        axios.get('article/get-articles').then(res => {

            setArticles(res.data);
        }).catch(err => {
            console.error('requestArticles err', err)
        });

    }, []);

    const deleteArticle = () => {

        console.log('articleDelete')
        console.log(articleDelete)

        // TODO supp mainPhoto path
        axios.delete(`/article/delete-article/${articleDelete._id}/${articleDelete.mainPhoto.path}`, config).then(res => {

            axios.get('article/get-articles').then(res => {

                const newArticlesList = res.data;
                setArticles(newArticlesList);
                handleClose();

            }).catch(err => {

                console.error('requestArticles err', err)
            });
        });
    }

    // ###### Update part ######

    const handleShowUpdate = (article) => {

        setArticleUpdate(article);
        setShowUpdate(true);
    };

    const handleCloseUpdate = () => {

        setArticleUpdate('');
        setShowUpdate(false);
    };

    return (
        <div className='container'>
            <h1>Supprimer un article</h1>

            <ul className='list-group'>

                {(articles && articles.length > 0) && articles.map(article => {

                    return (
                        <li key={uuidv4()} className='list-group-item'>

                            <div className="row">

                                <div className="col-lg-8">
                                    Date : {article.date} - Titre : {article.title} - Sous-titre : {article.subTitle}
                                </div>


                                <div className="col-lg-2">
                                    <button
                                        type='button'
                                        onClick={() => handleShowUpdate(article)}
                                        className='btn btn-outline-secondary btn-sm'
                                    >Modifier</button>
                                </div>

                                <div className="col-lg-2">
                                    <button
                                        type='button'
                                        onClick={() => handleShow(article)}
                                        className='btn btn-outline-danger btn-sm'
                                    >Supprimer</button>
                                </div>
                            </div>

                        </li>
                    );
                })}
            </ul>

            <Modal show={show} onHide={handleClose}>
                <form>

                    <Modal.Header closeButton>
                        <Modal.Title>Voulez-vous supprimer l'article suivant :</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <h4>{articleDelete.title}</h4>
                        <h5>{articleDelete.subTitle}</h5>
                        <p className="fst-italic">{articleDelete.date}</p>
                        <p>{articleDelete.article}</p>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Annuler
                        </Button>
                        <Button variant="danger" onClick={deleteArticle}>
                            Supprimer
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <UpdateArticle
                show={ showUpdate }
                close={() => handleCloseUpdate()}
                article={ articleUpdate }
            />
        </div>
    )
}
