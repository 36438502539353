import React from 'react';
import './Apnee.scss';

export default function Apnee() {
    return (
        <div className='container mt-3'>
            <h1>La plongée en apnée</h1>
            <div className="row mb-3">
                <div className="col-12 col-xl-8">
                    <img src="/photo/apnee/apnee-1.jpg" alt="photo-apnee.jpg" className='rounded img-fluid' />
                    <div className="text-end">
                        <small >Photo : Thibaut Dupin</small>
                    </div>
                </div>
                <div className="col-12 col-xl-4">
                    <h2>Ou plongée libre</h2>
                        <p>L'apnée consiste à suspendre sa ventilation de manière volontaire sous l'eau. 
                            Elle se pratique de différentes manières, dans différents milieux, à titre de loisir ou en compétition.</p>
                            <p>Par exemple on peut différencier l'apnée statique, consistant à rester le plus 
                                longtemps possible sous l'eau (plusieurs minutes) en économisant au maximum ses dépenses en oxygène 
                                et l'apnée dynamique qui a pour but de parcourir de grandes distances sous l'eau (pieds nus, avec palmes 
                                ou bi-palmes) soit en une fois soit en enchainant les séries avec des temps de récupération relativement courts.</p>
                                <p>L'apnée est une discipline possédant beaucoup de vertus en terme de relaxation ou de maîtrise de soi.</p>
                    </div>
            </div>
            <div className="row mt-3">
                <figure className="text-end">
                    <blockquote className="blockquote">
                        <p>Le vie d’un homme est l’intervalle entre sa première et sa dernière respiration.</p>
                    </blockquote>
                    <figcaption className="blockquote-footer">
                        Guillaume Néry <cite title="Source Title">- Profondeurs</cite>
                    </figcaption>
                </figure>
            </div>

            <div className="row">
                <div className="col">
                    
                </div>
            </div>
        </div>
    )
}
