import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { setToken } from '../../redux/token/tokenReducer';

export default function Login() {

    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [connected, setConnected] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { token } = useSelector(state => ({
        ...state.tokenReducer
    }));

    const [userLogin, setsetUserLogin] = useState({
        userId: '',
        password: ''
    })

    function parseJwt() {
        const tokenFromStorage = localStorage.getItem('token');

        if (tokenFromStorage) {
            const base64Payload = tokenFromStorage.split('.')[1];
            return JSON.parse(window.atob(base64Payload));
        } else {
            return false;
        }
    }

    const handleInputs = e => {

        if (e.target.classList.contains('inp-user-id')) {
            const newObjState = { ...userLogin, userId: e.target.value }
            setsetUserLogin(newObjState);
        } else if (e.target.classList.contains('inp-password')) {
            const newObjState = { ...userLogin, password: e.target.value }
            setsetUserLogin(newObjState);
        }
    }

    const connexion = () => {

        axios.post('/login', {
            userId: userLogin.userId,
            password: userLogin.password
        }).then(res => {

            const token = res.data;

            if (token) {
                setConnected(true);
            }

            localStorage.setItem('token', token);

            setShow(false);

            dispatch(setToken());

        }).catch(function (error) {
            console.log(error);
        });

        setsetUserLogin({
            userId: '',
            password: ''
        })
    }

    const deconnexion = () => {
        localStorage.removeItem('token');
        setConnected(false);
        dispatch(setToken());
    }

    useEffect(() => {

        let config = {};
    
        if (token) {
            config.headers = {
                Authorization: 'Bearer ' + token
            }

            axios.get("/login/check-token", config).then(res => {
    
                if (res.data.newToken) {
    
                    // localStorage.removeItem('token');
                    localStorage.setItem('token', res.data.newToken);
                    // dispatch(setToken());
                   
                } else {
    
                    localStorage.removeItem('token');
                    dispatch(setToken());
                }
    
            });
        }

        dispatch(setToken());

        if (token) {
            setConnected(true);
        } else {
            setConnected(false);
        }

    }, [token]);

    return (
        <>
            <Button variant="outline-primary" onClick={handleShow} className={!connected ? 'd-flex' : ' d-flex d-none'} >
                Connexion
            </Button>
            <Button variant="outline-danger" onClick={deconnexion} className={connected ? ' d-flex' : ' d-flex d-none'} >
                Deconnexion
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Se connecter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>

                        <div className="mb-3">
                            <label htmlFor="userId" className='form-label'>Identifiant</label>
                            <input
                                onChange={handleInputs}
                                value={userLogin.userId}
                                type="text"
                                id='userId'
                                placeholder='Entrez votre identifiant'
                                className='inp-user-id form-control' />
                        </div>


                        <div className="mb-3">
                            <label htmlFor="password" className='form-label'>Mot de passe</label>
                            <input
                                onChange={handleInputs}
                                value={userLogin.password}
                                type="password"
                                id='password'
                                placeholder='Entrez votre mot de passe'
                                className='inp-password form-control' />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={connexion}>
                        Connexion
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
