import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { renderError } from '../../utils/displayUtils';

export default function AddArticle() {

    const CONF = window?.confPub;
    const FORMS = CONF?.UI?.FORMS;
    const INPUTS = FORMS?.INPUTS;

    // Get Token from redux
    const tokenFromStorage = localStorage.getItem('token');
    let config = {};

    if (tokenFromStorage) {
        console.log('passe dans la config')
        config.headers = {
            Authorization: 'Bearer ' + tokenFromStorage,
            'Content-Type': 'multipart/form-data'
        }
    }

    const [wasSubmit, setWasSubmit] = useState(false);
    const [invalidArticle, setInvalidArticle] = useState(false);
    const [images, setImages] = useState(null);

    const [displayMessage, setDisplayMessage] = useState({
        success: false,
        failure: false
    });

    const [article, setArticle] = useState(
        {
            title: {
                value: '',
                valid: false,
                msgError: 'Le titre est obligatoire.'
            },
            subTitle: {
                value: '',
                valid: true,
                msgError: 'Le sous titre est obligatoire.'
            },
            date: {
                value: '',
                valid: false,
                msgError: 'La date est obligatoire.'
            },
            credit: {
                value: '',
                valid: true,
                msgError: 'Le crédit photo est obligatoire.'
            },
            teaser: {
                value: '',
                valid: false,
                msgError: 'Le teaser est obligatoire.'
            },
            article: {
                value: '',
                valid: false,
                msgError: 'Le contenu est obligatoire.'
            }
        }
    );

    const handleInputs = e => {

        const inputValue = e.target.value;
        let valid = true;

        switch (e.target.name) {
            case 'title':
            case 'date':
            case 'teaser':
            case 'article':

                if (inputValue === '') {
                    valid = false;
                }
                break;

            case 'credit':

            case 'subTitle':
            default:
                break;
        }

        const newArticle = {
            ...article,
            [e.target.name]: {
                value: inputValue,
                valid: valid
            }
        }

        setArticle(newArticle);
    }

    const onFileChange = (e) => {

        setImages(e.target.files);
    }

    const onSubmit = (e) => {

        e.preventDefault();
        setWasSubmit(true);

        const { validArticle, articleToAdd } = checkForm();

        if (validArticle && images) {

            setInvalidArticle(false);

            const formData = new FormData();
            formData.append('article', JSON.stringify(articleToAdd));

            for (let image of images) {
                formData.append('images', image);
            }

            axios.post("/article/add", formData, config).then(res => {

                resetInputs();

                setDisplayMessage({
                    ...displayMessage,
                    success: true
                });

            }).catch(err => {
                console.log("err", err);

                setDisplayMessage({
                    ...displayMessage,
                    failure: true
                });

            });
        } else {

            setInvalidArticle(true);
        }
    }

    const checkForm = () => {

        let validArticle = true;
        let articleToAdd = {};

        for (let i in article) {

            if (article.hasOwnProperty(i)) {
                validArticle = !article[i].valid ? false : validArticle;

                articleToAdd = {
                    ...articleToAdd,
                    [i]: article[i].value
                }
            }
        }

        return { validArticle, articleToAdd };
    }

    const resetInputs = () => {

        setWasSubmit(false);

        const newArticle = {
            title: {
                value: '',
                valid: false,
                msgError: 'Le titre est obligatoire.'
            },
            subTitle: {
                value: '',
                valid: true,
                msgError: 'Le sous titre est obligatoire.'
            },
            date: {
                value: '',
                valid: false,
                msgError: 'La date est obligatoire.'
            },
            credit: {
                value: '',
                valid: true,
                msgError: 'Le crédit photo est obligatoire.'
            },
            teaser: {
                value: '',
                valid: false,
                msgError: 'Le teaser est obligatoire.'
            },
            article: {
                value: '',
                valid: false,
                msgError: 'Le contenu est obligatoire.'
            }
        };
        setArticle(newArticle);

        setImages(null);
        document.getElementById('photo').value = null;
    }

    return (
        <div className='container'>
            <h1>Ajouter un article</h1>

            {displayMessage?.success && (
                <div className="alert alert-success d-flex justify-content-between" role="alert">
                    <span>{FORMS?.MSG?.ADD_ARTICLE_SUCCESS}</span>
                    <button type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => setDisplayMessage({ ...displayMessage, success: false })} />
                </div>
            )}

            {displayMessage?.failure && (
                <div className="alert alert-danger d-flex justify-content-between" role="alert">
                    <span>{FORMS?.MSG?.ADD_ARTICLE_FAILURE}</span>
                    <button type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => setDisplayMessage({ ...displayMessage, failure: false })} />
                </div>
            )}

            <form onSubmit={onSubmit} className="container-form">

                <div className="row">

                    <div className="col-lg-6">
                        <label htmlFor={INPUTS?.TITLE?.NAME}
                            className='form-label'>
                            {INPUTS?.TITLE?.LABEL}
                        </label>
                        <input
                            id={INPUTS?.TITLE?.NAME}
                            name={INPUTS?.TITLE?.NAME}
                            type="text"
                            value={article.title.value}
                            placeholder={INPUTS?.TITLE?.PLACEHOLDER}
                            className={`form-control ${wasSubmit
                                && !article.title.valid ? 'is-invalid' : ''}`}
                            onChange={handleInputs} />
                        {wasSubmit && !article.title.valid && renderError(article.title.msgError)}
                    </div>
                </div>

                <div className="row">

                    <div className="col-lg-6">
                        <label htmlFor={INPUTS?.SUB_TITLE?.NAME}
                            className='form-label'>
                            {INPUTS?.SUB_TITLE?.LABEL}
                        </label>
                        <input
                            id={INPUTS?.SUB_TITLE?.NAME}
                            name={INPUTS?.SUB_TITLE?.NAME}
                            type="text"
                            value={article.subTitle.value}
                            placeholder={INPUTS?.SUB_TITLE?.PLACEHOLDER}
                            className={`form-control ${wasSubmit
                                && !article.subTitle.valid && 'is-invalid'}`}
                            onChange={handleInputs} />
                        {wasSubmit && !article.subTitle.valid && renderError(article.subTitle.msgError)}
                    </div>
                </div>

                <div className="row">

                    <div className="col-lg-3">
                        <label htmlFor={INPUTS?.DATE?.NAME}
                            className='form-label'>
                            {INPUTS?.DATE?.LABEL}
                        </label>
                        <input
                            id={INPUTS?.DATE?.NAME}
                            name={INPUTS?.DATE?.NAME}
                            type="date"
                            value={article.date.value}
                            className={`form-control ${wasSubmit
                                && !article.date.valid && 'is-invalid'}`}
                            onChange={handleInputs} />
                        {wasSubmit && !article.date.valid && renderError(article.date.msgError)}
                    </div>

                    <div className="col-lg-4">
                        <label htmlFor={INPUTS?.CREDIT?.NAME}
                            className='form-label'>
                            { INPUTS?.CREDIT?.LABEL } 
                        </label>
                        <input
                            id={ INPUTS?.CREDIT?.NAME } 
                            name={ INPUTS?.CREDIT?.NAME } 
                            type="text"
                            value={article.credit.value}
                            placeholder={ INPUTS?.CREDIT?.PLACEHOLDER } 
                            className={`form-control ${wasSubmit 
                                && !article.credit.valid && 'is-invalid'}`}
                            onChange={handleInputs} />
                        {wasSubmit && !article.credit.valid
                            && renderError(article.credit.msgError)}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-7">
                        <label htmlFor={ INPUTS?.TEASER?.NAME } 
                            className='form-label'>
                            { INPUTS?.TEASER?.LABEL } 
                        </label>
                        <textarea
                            name={ INPUTS?.TEASER?.NAME } 
                            id={ INPUTS?.TEASER?.NAME } 
                            value={article.teaser.value}
                            placeholder={ INPUTS?.TEASER?.PLACEHOLDER } 
                            className={`form-control ${wasSubmit
                                && !article.teaser.valid && 'is-invalid'}`}
                            rows="3"
                            onChange={handleInputs}
                        ></textarea>
                        {wasSubmit && !article.teaser.valid && renderError(article.teaser.msgError)}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-7">
                        <label htmlFor={ INPUTS?.ARTICLE?.NAME } 
                            className='form-label'>
                            { INPUTS?.ARTICLE?.LABEL } 
                        </label>
                        <textarea
                            name={ INPUTS?.ARTICLE?.NAME } 
                            id={ INPUTS?.ARTICLE?.NAME } 
                            value={article.article.value}
                            placeholder={ INPUTS?.ARTICLE?.PLACEHOLDER } 
                            className={`form-control ${wasSubmit
                                && !article.article.valid && 'is-invalid'}`}
                            rows="6"
                            onChange={handleInputs}
                        ></textarea>
                        {wasSubmit && !article.article.valid && renderError(article.article.msgError)}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <label htmlFor={ INPUTS?.PHOTO?.NAME } 
                        className='form-label'>
                           { INPUTS?.PHOTO?.LABEL } 
                        </label>
                        <input
                            id={ INPUTS?.PHOTO?.NAME } 
                            name={ INPUTS?.PHOTO?.NAME } 
                            type="file"
                            files={images}
                            className={`form-control ${wasSubmit
                                && !images && 'is-invalid'}`}
                            onChange={onFileChange}
                            multiple
                        />

                        {wasSubmit && !images && renderError('Vous devez joindre au moins une photo.')}
                    </div>
                </div>

                <div className='mt-3'>
                    <button type='submit' className='btn btn-primary'>Ajouter</button>
                </div>
            </form>
        </div>
    )
}
