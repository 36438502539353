import React from 'react';
import './Contact.scss';

import photoPiscine1 from 'Assets/activite/piscine-1.jpg';
import photoPiscine2 from 'Assets/activite/piscine-2.jpg';

export default function Contact() {
  return (
    <div className='container'>
      <h1>Contact</h1>

      <div className="row">
        <div className="col-lg-4">
          <h2>Pour nous contacter :</h2>
          <dl className="row">
            <dt className="col-md-4 text-nowrap">par email :</dt>
            <dd className="col-md-8"><a href="mailto:usmmplongee@gmail.com">usmmplongee@gmail.com</a></dd>
            <dt className="col-md-4 text-nowrap">par téléphone :</dt>
            <dd className="col-md-8"><a href="tel:0613880322">0613880322</a></dd>
          </dl>
          <h2>Horaires :</h2>
          <dl>
            <dt className="col-md-4 text-nowrap">Plongée :</dt>
            <dd className="col-md-8">
              <ul>
                <li>Mercredi 20h - 22h</li>
              </ul>
            </dd>
            <dt className="col-md-4 text-nowrap">Apnée :</dt>
            <dd className="col-md-8">
              <ul>
                <li>Mardi 20h - 22h : dynamique</li>
                <li>Mercredi 20h - 22h : statique</li>
                <li>Vendredi 20h - 22h : dynamique</li>
              </ul>
            </dd>
            <dt className="col-md-4 text-nowrap">Nage :</dt>
            <dd className="col-md-8">
              <ul>
                <li>Lundi 20h - 22h</li>
                <li>Mercredi 20h - 22h</li>
              </ul>
            </dd>
          </dl>
          <h2>Adresse :</h2>
          <p>Rue des Closiers, 45200 Montargis</p>
        </div>

        <div className="col-xl-8 div-Plongee">
          <img src={photoPiscine1} />
          <div className="text-end">
            <small >Piscine du Lac à Montargis de 2 à 4 m de fond</small>
          </div>
        </div>

        <div className="row mt-5">
          {/* <div className="col-xl-8">
            <img src={photoPiscine2} />
            <div className="text-end">
              <small >Petit bassin pour l'apnée statique</small>
            </div>
          </div> */}

          <div className="col-md-12">
            <h2>Plan du site :</h2>

            <div className='text-left'>
              <iframe
                className='rounded'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2670.0469723299075!2d2.7376771162409685!3d47.99347936916421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ef80997f381759%3A0x784a21d5fab41e1a!2sPiscine%20du%20Lac!5e0!3m2!1sfr!2sfr!4v1652362651333!5m2!1sfr!2sfr"
                width="100%"
                height="800"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>

      </div>

      <div className='contact-footer-spacer'></div>



    </div>
  )
}
