import React from 'react';
import './Formateur.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { getFormateurs } from '../../../redux/formateur/formateurReducer';

import FormateurCard from './FormateurCard/FormateurCard'; 
import './Formateur.scss';

export default function Formateur() {

  const { formateurs } = useSelector(state => ({
    ...state.formateurReducer
  }));

  const { config } = useSelector(state => ({
    ...state.configReducer
  }));

  const dispatch = useDispatch();

  // se lance quand le reste est finit d'afficher pour la première fois 
  useEffect(() => {

    if (formateurs.length === 0) {
      dispatch(getFormateurs());
    }

  }, []);

  return (
    <div className='mt-3'>
      <h2 className='mb-3'>Nos Formateurs :</h2>
      <div className="card-container row">

        {(config.FORMATEURS && config.FORMATEURS.length > 0 )&& config.FORMATEURS.map(formateur => {

           return (
             <FormateurCard key={uuidv4()} formateur={formateur} />
           )

        })}

      </div>

      <div className='formateur-footer-spacer'></div>

    </div>
  )
}
