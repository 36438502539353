import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import formateurReducer from './formateur/formateurReducer';
import configReducer from './config/configReducer';
import tokenReducer from './token/tokenReducer';
import errorReducer from './error/errorReducer';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
    formateurReducer,
    configReducer,
    tokenReducer, 
    errorReducer
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

export default store; 