import React from 'react';
import './Activite.scss';
import { Link, Outlet, NavLink } from 'react-router-dom';

export default function Activite() {
  return (
    <div className='container'>
      <h1>Activités proposées :</h1>
      <p>La plongée sous-marine est une activité consistant à rester sous l'eau, 
        soit en apnée dans le cas de la plongée libre, soit en respirant à l'aide d'un narguillé 
        (comme dans Tintin et le trésor de Rackham le rouge) ou le plus souvent en s'équipant 
        d'une bouteille de plongée dans le cas de la plongée en scaphandre autonome.
      </p>

      <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavLink
            to='/activite/plongee'
            className='nav-link'
          >Plongée</NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to='/activite/apnee'
            className='nav-link'
          >Apnée</NavLink>
        </li>
      </ul>

      <Outlet />

      <div className='activite-footer-spacer'></div>
    </div>
  )
}
