import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Buffer } from 'buffer';

export default function AddPhoto(props) {

    const [image, setImage] = useState('');

    useEffect(() => {

        getPhoto(props.image)

    }, [props.image]);

    const getPhoto = (imageP) => {

        if (imageP && imageP.path) {

            axios.get(`${window.location.origin}/article/get-photo/${imageP.path}`, {
                responseType: 'arraybuffer'
            }).then(response => {

                
                const base64 = Buffer.from(response.data, 'base64') //andbuf.toString('base64').

                setImage({ path: "data:;base64," + base64.toString('base64'), legend: imageP.legend, credit: imageP.credit });
    
            }).catch(err => {
                console.error('requestArticles err', err)
            });
        }
        
    }

    return (
        <>
            <img
                src={image && image.path}
                className="card-img-top article-img"
                alt={image.legend} />
        </>
    )
}
