import React from 'react';
import { Link, Outlet, NavLink } from 'react-router-dom';

export default function Sorties() {
    return (
        <div className='container'>

            <h1 className='my-3'>Les sorties club</h1>

            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <NavLink
                        to='/sorties/carrieres'
                        className='nav-link'
                    >Carrière</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        to='/sorties/mer'
                        className='nav-link disabled'
                    >Mer</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink
                        to='/sorties/voyages-club'
                        className='nav-link disabled'
                    >Voyages Club</NavLink>
                </li>
            </ul>

            <Outlet />

            <div className='activite-footer-spacer'></div>
        </div>
    )
}
