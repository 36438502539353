import React, { useEffect, useState } from 'react';

import './ArticleDetail.scss'
import { Carousel } from 'react-bootstrap';

import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify'
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import AddPhoto from '../Article/AddPhoto';


export default function ArticleDetail(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const [article, setArticle] = useState(
        location && location.state && location.state.article
    );

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const [selectedImage, setSelectedImage] = useState({});
    const [showGallery, setShowGallery] = useState(false);

    const handleCloseGallery = () => {
        setShowGallery(false);
    }

    const handleShowGallery = (e, img) => {
        setSelectedImage(img);
        setShowGallery(true);
    }

    useEffect(() => {


        console.log('article');
        console.log(article);


        if (!article) {

            const id = window.location.href.split('/').pop();

            axios.get('/article/read/' + id).then(res => {

                setArticle(res.data);
    
                // const photos = article.otherPhotos;
                // photos.unshift(article.mainPhoto);
    
                // setArticle({
                //     ...article,
                //     otherPhotos: photos
                // });
    
            }).catch(err => {
                console.error('requestArticle err', err)
            });
        }

    }, [])

    return (

        <div className='container'>

            <div className="col-2">
                <button className='btn-close' type='button' onClick={ () => navigate('/') }/>
            </div>

            <div className="col-12 mt-4">

                <div className="card shadow-sm h-100">
                    <Carousel
                        activeIndex={index}
                        onSelect={handleSelect}
                        interval={null}
                    >
                        {
                            article && article.otherPhotos && article.otherPhotos.map(photo => {
                                return (
                                    <Carousel.Item
                                        key={photo.id}
                                        onClick={(e) => handleShowGallery(e, photo)}
                                    >

                                        <AddPhoto key={ uuidv4 } image={photo} />

                                        <Carousel.Caption>
                                            {/* <h3>{photo.legend}</h3>
                                            <p>{photo.credit}</p> */}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                );
                            })
                        }
                    </Carousel>

                    <div className="card-body overflow-last mt-3">
                        <p className='float-end fst-italic'>{article && article.date}</p>
                        <h3 className="">{article && article.title}</h3>
                        <h4 className="text-muted">{article && article.subTitle}</h4>
                        <p
                            className="card-text"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article && article.teaser + ' ' + article && article.article) }}></p>

                        <p>Crédit photos : {article && article.credit}</p>
                        {/* <p>{article && article._id}</p> */}
                    </div>

                    <Modal show={showGallery} onHide={handleCloseGallery} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title>{selectedImage.legend} - {selectedImage.credit}</Modal.Title>
                        </Modal.Header>
                        <img
                            src={selectedImage.path}
                            className="img-fluid"
                            alt={selectedImage.legend}
                        />
                    </Modal>
                </div>
            </div>
        </div>
    );
}
