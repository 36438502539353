const axios = require('axios').default;

const INITIAL_STATE = {
    config: {}
}

function configReducer(state = INITIAL_STATE, action) {

    switch (action.type) {
        case 'LOAD_CONFIGURATION': {

            return {
                ...state,
                config: action.payload
            }
        }
    }

    return state;
}

export default configReducer;

export const getConfig = () => dispatch => {

    axios.get('/get-config')
        .then(function (response) {
            // handle success
            dispatch({
                type: 'LOAD_CONFIGURATION',
                payload: response.data.config
            });
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
        });
}