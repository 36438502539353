import React from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';

export default function UpdateArticle(props) {

    console.log('props.article')
    console.log(props.article)




    return (
        <Modal show={props.show}  onHide={props.close}
        // onHide={handleClose}
        >
            <form>

                <Modal.Header closeButton>
                    <Modal.Title>Modifier l'article :</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <h4>{articleDelete.title}</h4>
                    <h5>{articleDelete.subTitle}</h5>
                    <p className="fst-italic">{articleDelete.date}</p>
                    <p>{articleDelete.article}</p> */}

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={props.close}
                        >
                        Annuler
                    </Button>
                    <Button
                        variant="danger"
                        // onClick={deleteArticle}
                        >
                        Modifier l'article
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
