import React from 'react';
import './NavBar.scss';
import usmmpLogo from '../../Assets/LogoUSMMCouleur.png';
import { Navbar, Container, Nav, NavDropdown, Button, Modal } from 'react-bootstrap';
import Login from '../Login/Login';
import { useSelector } from 'react-redux';

export default function NavBar() {

    const CONF = window?.confPub;
    const PAGES = CONF?.ROUTES?.PAGES;

    const { token } = useSelector(state => ({
        ...state.tokenReducer
    }));

    return (
        <>
            <Navbar sticky="top" bg="light" expand="lg">
                <Container className="container-fluid">
                    <Navbar.Brand href="/"><img className='img-fluid logo-size' src={usmmpLogo} alt="logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href={PAGES?.BLOG?.LINK}>
                                {PAGES?.BLOG?.LABEL}
                            </Nav.Link>
                            <NavDropdown title={PAGES?.ACTIVITES?.LABEL} id="basic-nav-dropdown">
                                <NavDropdown.Item href={PAGES?.ACTIVITES?.LINKS?.PLONGEE?.LINK}>
                                    {PAGES?.ACTIVITES?.LINKS?.PLONGEE?.LABEL}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={PAGES?.ACTIVITES?.LINKS?.APNEE?.LINK}>
                                    {PAGES?.ACTIVITES?.LINKS?.APNEE?.LABEL}
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item className='disabled' href={PAGES?.ACTIVITES?.LINKS?.INSCRIPTION?.LINK}>
                                    {PAGES?.ACTIVITES?.LINKS?.INSCRIPTION?.LABEL}
                                </NavDropdown.Item>
                            </NavDropdown>
                            {token && <NavDropdown title={PAGES?.FORMATIONS?.LABEL} id='formations-nav-dropdown'>
                                <NavDropdown.Item href={PAGES?.FORMATIONS?.LINKS?.PLONGEE?.LINK}
                                    className='disabled'>
                                    {PAGES?.FORMATIONS?.LINKS?.PLONGEE?.LABEL}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={PAGES?.FORMATIONS?.LINKS?.APNEE?.LINK}
                                    className='disabled'>
                                    {PAGES?.FORMATIONS?.LINKS?.APNEE?.LABEL}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={PAGES?.FORMATIONS?.LINKS?.BIO?.LINK}>
                                    {PAGES?.FORMATIONS?.LINKS?.BIO?.LABEL}
                                </NavDropdown.Item>
                            </NavDropdown>
                            }
                            <NavDropdown title={PAGES?.SORTIES?.LABEL} id="basic-nav-dropdown">
                                <NavDropdown.Item href={PAGES?.SORTIES?.LINKS?.CARRIERES?.LINK}>
                                    {PAGES?.SORTIES?.LINKS?.CARRIERES?.LABEL}
                                </NavDropdown.Item>
                                <NavDropdown.Item className='disabled'
                                    href={PAGES?.SORTIES?.LINKS?.MER?.LINK}>
                                    {PAGES?.SORTIES?.LINKS?.MER?.LABEL}
                                </NavDropdown.Item>
                                <NavDropdown.Item className='disabled'
                                    href={PAGES?.SORTIES?.LINKS?.VOYAGE_CLUB?.LINK}>
                                    {PAGES?.SORTIES?.LINKS?.VOYAGE_CLUB?.LABEL}
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href={PAGES?.CONTACT?.LINK}>
                                {PAGES?.CONTACT?.LABEL}
                            </Nav.Link>
                            <Nav.Link href={PAGES?.CALENDRIER?.LINK}>
                                {PAGES?.CALENDRIER?.LABEL}
                            </Nav.Link>
                            <NavDropdown title={PAGES?.ABOUT?.LABEL} id="basic-nav-dropdown">
                                <NavDropdown.Item href={PAGES?.ABOUT?.LINKS?.FORMATEURS?.LINK}>
                                    {PAGES?.ABOUT?.LINKS?.FORMATEURS?.LABEL}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={PAGES?.ABOUT?.LINKS?.BUREAU?.LINK}>
                                    {PAGES?.ABOUT?.LINKS?.BUREAU?.LABEL}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={PAGES?.ABOUT?.LINKS?.REGLEMENT?.LINK}>
                                    {PAGES?.ABOUT?.LINKS?.REGLEMENT?.LABEL}
                                </NavDropdown.Item>
                                <NavDropdown.Item href={PAGES?.ABOUT?.LINKS?.STATUTS?.LINK}>
                                    {PAGES?.ABOUT?.LINKS?.STATUTS?.LABEL}
                                </NavDropdown.Item>
                            </NavDropdown>
                            {token && <Nav.Link href="/ajouter-article">
                                Ajouter un article
                            </Nav.Link>}
                            {token && <Nav.Link href="/supprimer-article">
                                Modifier un article
                            </Nav.Link>}
                        </Nav>
                        <Login />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}
