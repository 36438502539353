import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

export default function ErrorModal(props) {

    const dispatch = useDispatch();
    const { error } = useSelector(state => ({
        ...state.errorReducer
    }));

    const handleClose = () => {

        dispatch({
            type: 'SET_SHOW_ERROR',
            payload: {
              show: false,
              message: ''
            }
          })
    };

    return (
        <>
            <Modal show={error && error.show ? error.show : false} onHide={handleClose}>
                <form>

                    <Modal.Header closeButton>
                        <Modal.Title>Erreur : </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <h3>{error && error.message ? error.message : ''}</h3>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Fermer
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}
