import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/fr';
import './Calendrier.scss';
import ErrorModal from '../../Components/ErrorModal/ErrorModal';

export default function Calendrier() {

  // const tokenFromStorage = localStorage.getItem('token');
  const dispatch = useDispatch();
  let config = {};
  const formatInput = 'YYYY-MM-DDThh:mm';

  const { token } = useSelector(state => ({
    ...state.tokenReducer
  }));

  if (token) {
    config.headers = {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  }

  const [events, setEvents] = useState([
    {
      title: '',
      start: '',
      end: ''
    }
  ]);

  const [newEvent, setNewEvent] = useState({
    title: {
      value: '',
      valid: false
    },
    start: {
      value: '',
      valid: false

    },
    end: {
      value: '',
      valid: false
    }
  });

  const [wasSubmit, setWasSubmit] = useState(false);

  const [eventDelete, setEventDelete] = useState('');
  const [showDelete, setShowDelete] = useState(false);

  const [eventUpdate, setEventUpdate] = useState({
    title: {
      value: '',
      valid: '',
      id: false
    },
    start: {
      value: '',
      valid: '',
      id: false
    },
    end: {
      value: '',
      valid: '',
      id: false
    }
  });
  const [showUpdate, setShowUpdate] = useState(false);

  useEffect(() => {

    getEventsFromServer();
  }, []);



  const getEventsFromServer = () => {

    axios.get('calendrier/all-events').then(res => {

      console.log(res.data);

      const serverEvents = res.data;

      if (serverEvents && serverEvents.length > 0) {

        const eventDisplay = [];

        serverEvents.map(eventFromServer => {

          const eventToAdd = {
            title: eventFromServer.title,
            start: new Date(eventFromServer.start),
            end: new Date(eventFromServer.end),
            _id: eventFromServer._id
          }

          eventDisplay.push(eventToAdd);
        });

        setEvents([
          ...eventDisplay
        ]);
      }

    }).catch(err => {

      openErrorModal(err);
    })
  }

  const localizer = momentLocalizer(moment);

  const handleInput = (event) => {

    let valid = true;
    const value = event.target.value;
    const name = event.target.name;

    switch (name) {

      case 'title':

        if (!new RegExp(/^[A-Za-zÀ-ú '\-\(\)]{1,30}$/).test(value.trim())) {
          valid = false
        }

        break;
      case 'start':
      case 'end':

        if (!value) {

          valid = false
        }

        break;
    }

    setNewEvent({
      ...newEvent,
      [name]: {
        value: value,
        valid: valid
      }
    });
  }

  const handleUpdate = (event) => {

    let valid = true;
    const value = event.target.value;
    const name = event.target.name;

    switch (name) {

      case 'title':

        if (!new RegExp(/^[A-Za-zÀ-ú '\-]{1,20}$/).test(value.trim())) {
          valid = false
        }

        break;
      case 'start':
      case 'end':

        if (!value) {

          valid = false
        }
        break;
      default:
        valid = true;
    }

    setEventUpdate({
      ...eventUpdate,
      [name]: {
        value: value,
        valid: valid
      }
    })

  }

  const sendForm = (event) => {

    event.preventDefault();

    setWasSubmit(true);

    let validEvent = true;
    let sendEvent = {};

    for (let i in newEvent) {

      if (newEvent.hasOwnProperty(i)) {

        validEvent = !newEvent[i].valid ? false : validEvent;
        sendEvent = {
          ...sendEvent,
          [i]: newEvent[i].value
        }
      }
    }

    if (validEvent) {

      axios.post('/calendrier/add-event', {
        event: JSON.stringify(sendEvent)
      }, config).then(res => {
        console.log('send');
        console.log(res);

        getEventsFromServer();

      }).catch((err) => {

        openErrorModal(err);
      });
    }
  }


  const deleteEvent = () => {

    console.log('Supp')
    console.log(eventDelete)

    axios.delete(`/calendrier/delete-event/${eventDelete._id}`,
      config).then(res => {

        handleCloseDelete();
        getEventsFromServer();

      }).catch(err => {

        openErrorModal(err);
      });
  }

  const updateEvent = () => {

    console.log('update batârd');

    console.log('eventUpdate')
    console.log(eventUpdate)

    let validEvent = true;
    let sendEvent = {};

    for (let i in newEvent) {

      if (eventUpdate.hasOwnProperty(i)) {

        validEvent = !eventUpdate[i].valid ? false : validEvent;
        sendEvent = {
          ...sendEvent,
          [i]: eventUpdate[i].value
        }
      }
    }

    sendEvent = {
      ...sendEvent,
      _id: eventUpdate._id.value
    }

    console.log('sendEvent')
    console.log(sendEvent)

    if (validEvent) {
      axios.put(`/calendrier/update-event/${eventUpdate._id}`, {
        event: JSON.stringify(sendEvent)
      }, config).then((res) => {

        console.log('send');
        console.log(res);
        setShowUpdate(false);
        getEventsFromServer();
      }).catch((err) => {

        openErrorModal(err);
      });
    }

  }

  const handleShowDelete = (eventToDelete) => {

    setEventDelete(eventToDelete);
    setShowDelete(true);
  };

  const handleCloseDelete = () => {

    setEventDelete('');
    setShowDelete(false);
  };

  const handleShowUpdate = (eventToUpdate) => {

    const eventTO = {
      _id: {
        value: eventToUpdate._id,
        valid: true
      },
      title: {
        value: eventToUpdate.title,
        valid: true
      },
      start: {
        value: moment(eventToUpdate.start).format(formatInput),
        valid: true
      },
      end: {
        value: moment(eventToUpdate.stendart).format(formatInput),
        valid: true
      }
    }

    setEventUpdate(eventTO);
    setShowUpdate(true);
  };

  const handleCloseUpdate = () => {

    // setEventUpdate('');
    setShowUpdate(false);
  };

  const openErrorModal = (err) => {

    const message = err && err.response && err.response.status
      ? 'Vous n\'êtes pas autorisé à effectuer cette action.'
      : 'Une erreur est survenue.'

    dispatch({
      type: 'SET_SHOW_ERROR',
      payload: {
        show: true,
        message: message
      }
    });
  }

  const displayError = (message) => {

    return (
      <small className='text-danger'>{message}</small>
    );
  }

  return (
    <div className='container'>
      <div>
        <h1 className='my-3'>Calendrier de l'USMM</h1>
        <Calendar
          localizer={localizer}
          events={events && events.length > 0 && events}
          startAccessor="start"
          endAccessor="end"
          messages={{
            next: 'suivant',
            previous: 'précédent',
            today: "aujourd'hui",
            month: 'mois',
            week: 'semaine',
            day: 'jour',
            event: 'Sortie',
            time: 'Durée',
            allDay: 'Toutes la journée'
          }}
          style={{ height: 500 }}
        />
      </div>

      {token &&
        <div>
          <hr />
          <h2>Nouvel évènement</h2>
          <form onSubmit={sendForm}>

            <div className="row">

              <div className="col-lg-6">
                <label htmlFor="title" className='form-label'>Nom de l'évènement</label>
                <input type="text"
                  id='title'
                  name='title'
                  value={newEvent.title.value}
                  placeholder=''
                  className={`form-control ${wasSubmit && !newEvent.title.valid && 'is-invalid'}`}
                  onChange={handleInput} />

                {wasSubmit && !newEvent.title.valid && displayError('Le titre de l\'évènement est obligatoire.')}
              </div>

            </div>

            <div className="row">

              <div className="col-lg-3 mt-3">
                <label htmlFor="start" className='ms-2'>Début de l'évènement :</label>
                <input type="datetime-local"
                  id='start'
                  name='start'
                  value={newEvent.start.value}
                  placeholder=''
                  className={`form-control ${wasSubmit && !newEvent.start.valid && 'is-invalid'}`}
                  onChange={handleInput} />

                {wasSubmit && !newEvent.start.valid && displayError('Date de début d\'évènement obligatoire.')}
              </div>

              <div className="col-lg-3 mt-3">
                <label htmlFor="end" className='ms-2'>Fin de l'évènement :</label>
                <input type="datetime-local"
                  id='end'
                  name='end'
                  value={newEvent.end.value}
                  placeholder=''
                  className={`form-control ${wasSubmit && !newEvent.end.valid && 'is-invalid'}`}
                  onChange={handleInput} />

                {wasSubmit && !newEvent.end.valid && displayError('Date de fin d\'évènement obligatoire.')}
              </div>

            </div>

            <div className="row mt-3">
              <div className="col-lg-2">
                <button type='submit' className='btn btn-primary'>
                  Enregistrer l'évènement
                </button>
              </div>
            </div>

          </form>
        </div>

      }

      {token &&
        <div>
          <h2>Liste des évènements :</h2>

          <ul className='list-group'>
            {events.map(eventAdd => {

              return (
                <li className='list-group-item'>
                  <di className="row">
                    <div className="col-9">
                      Non : <span className='fw-bold'> {eventAdd.title}</span> - du : {moment(eventAdd.start).format('DD/MM/YYYY à hh:mm')} au {moment(eventAdd.end).format('DD/MM/YYYY à hh:mm')}
                    </div>
                    <div className="col-3">
                      <button onClick={() => handleShowUpdate(eventAdd)}
                        className='btn btn-primary'>modifier</button>
                      <button
                        onClick={() => handleShowDelete(eventAdd)}
                        className='btn btn-danger ms-3'>supprimer</button>
                    </div>
                  </di>
                </li>
              )

            })}
          </ul>

        </div>

      }

      {token &&
        <Modal show={showDelete} onHide={handleCloseDelete}>
          <form>

            <Modal.Header closeButton>
              <Modal.Title>Voulez-vous supprimer l'évènement suivant :</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <h4>{eventDelete.title}</h4>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDelete}>
                Annuler
              </Button>
              <Button variant="danger" onClick={deleteEvent}>
                Supprimer
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      }

      {token &&
        <Modal show={showUpdate} onHide={handleCloseUpdate}>
          <form>

            <Modal.Header closeButton>
              <Modal.Title>Voulez-vous mettre-à-jour l'évènement suivant :</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <div>
                <label htmlFor="">Nom de l'évènement</label>
                <input type="text"
                  name='title'
                  value={eventUpdate.title.value}
                  className='form-control'
                  onChange={handleUpdate}
                />
              </div>

              <div className='mt-2'>
                <label htmlFor="start" className='ms-2'>Début de l'évènement :</label>
                <input type="datetime-local"
                  name='start'
                  value={eventUpdate.start.value}
                  className='form-control'
                  onChange={handleUpdate}
                />
              </div>

              <div className='mt-2'>
                <label htmlFor="end" className='ms-2'>Fin de l'évènement :</label>
                <input type="datetime-local"
                  name='end'
                  value={eventUpdate.end.value}
                  className='form-control'
                  onChange={handleUpdate}
                />
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseUpdate}>
                Annuler
              </Button>
              <Button variant="danger" onClick={updateEvent}>
                Mettre-à-jour
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      }

      <ErrorModal />

    </div>
  )
}
