const INITIAL_STATE = {
    error: {
        show: false,
        message: ''
    }
}

function errorReducer(state = INITIAL_STATE, action) {

    switch(action.type) {
        case 'SET_SHOW_ERROR': {

            return {
                ...state,
                error:  action.payload
            }
        }
    }

    return state; 
}

export default errorReducer; 