import React from 'react';
import Article from './Article/Article';

export default function Blog() {

  return (
    <div className='container'>

      <h1>La vie du club</h1>

      <Article />

      <br />
    </div>
  )
}
