import React, { useState, useEffect } from 'react';
import './Carriere.scss';
import { v4 as uuidv4 } from 'uuid';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';

import { Carousel } from 'react-bootstrap';

export default function Carrieres() {

  const [gallery, setGallery] = useState([]);

  const [selectedImage, setSelectedImage] = useState({});

  const [index, setIndex] = useState(0);

  useEffect(() => {

    const images = importAll(require.context('../../../Assets/sorties/beffes', false, /\.(png|jpe?g|svg|JPG)$/));

    let newGallery = [];

    images && images.length > 0 && images.map(image => {

      newGallery = [
        ...newGallery,
        {
          photo: image
        }
      ]
    });


    setGallery(newGallery);

  }, []);


  const handleShowGallery = (e, img) => {
    setSelectedImage(img);
    // setShowGallery(true);
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
};



  return (
    <div className='carriere-container'>
      <h2>Carrières</h2>
      <h3>Beffes</h3>
      <p>
        Située à proximité du Montargeois, la carrière de Beffes permet de nous entrainer tout au long de l'année.
        Celle-ci permet de pratiquer des exercices jusqu'à 20 mètres et propose différents centres d'intérêt pour l'exploration.
        Les infrastructures tant sous-marines que terrestres apportent un certain confort. Des douches chaudes et des vestiaires
        intérieurs sont à disposition et permetent de se réchauffer rapidement entre deux plongées.
        Nous effectuons également des week-end complets où nous pouvons louer, à cette occasion, des bungalows attenants.
      </p>

      <div className="card shadow-sm h-100">

        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          interval={null}
        >
          {
            gallery && gallery.length > 0 && gallery.map(photo => {
              return (
                <Carousel.Item
                // key={photo.id}
                key={uuidv4}
                onClick={(e) => handleShowGallery(e, photo)}
                >

                  <img className='photo-dimension' key={uuidv4} src={photo.photo} />

                  <Carousel.Caption>
                    {/* <h3>{photo.legend}</h3>
                                            <p>{photo.credit}</p> */}
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })
          }
        </Carousel>
      </div>

    </div>
  )
}

function importAll(r) {
  return r.keys().map(r);
}


