import React from 'react'
import { Outlet, NavLink } from 'react-router-dom';


export default function Formations() {
  return (
    <div className='container'>
        <h1>Formations :</h1>

        <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavLink
            to='/activite/plongee'
            className='nav-link disabled'
          >Plongée</NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to='/activite/apnee'
            className='nav-link disabled'
          >Apnée</NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to='/formations/bio'
            className='nav-link'
          >Bio</NavLink>
        </li>
      </ul>

      <Outlet />

    </div>
  )
}
