import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import './Bureau.scss';
import scubaGroup from '../../../Assets/montargis-group.JPG';

export default function Bureau() {

  const { config } = useSelector(state => ({
    ...state.configReducer
  }));
 
  const checkMember = (membre, defaultLibelle) => {

    if (membre) {

      if (!membre.LIBELLE) {
        membre.LIBELLE = defaultLibelle;
      }

      if (!membre.NOM && !membre.PRENOM) {
        membre.NOM = 'Poste non occupé';
        membre.PRENOM = '';
      } else {

        if (!membre.NOM) {
          membre.NOM = 'Nom non renseigné';
        }

        if (!membre.PRENOM) {
          membre.PRENOM = 'Prénom non renseigné';
        }
      }

    } else {
      return {
        "LIBELLE": defaultLibelle,
        "NOM": "Poste non occupé",
        "PRENOM": ""
      }
    }

    return membre;
  }

  const buildBureauMemberRow = (member) => {

    return (
      <>
        <dt key={uuidv4()}>{member.LIBELLE}</dt>
        <dd key={uuidv4()}>{member.PRENOM} {member.NOM}</dd>
      </>
    )

  }

  const buildLineOrListMember = (members) => {

    if (members.length === 1 && false) {
      console.log("passe là ")
      console.log(members[0]);

      const member = members[0];

      return (
        <>
          <dd>{member.PRENOM} {member.NOM} - {member.FONCTION} </dd>
        </>
      )
    } else if (members.length > 0) {

      return (
        <dd>
          <ul>
            {members.map(member => {
              return (
                <li key={uuidv4()}>{member.PRENOM} {member.NOM} - {member.FONCTION}</li>
              )
            })}
          </ul>
        </dd>
      )

    } else {

      return (<></>)
    }

  }

  const BUREAU = config.BUREAU ? config.BUREAU : {};
  const PRESIDENT = checkMember(BUREAU.PRESIDENT);
  const VICE_PRESIDENT = checkMember(BUREAU.VICE_PRESIDENT);
  const TRESORIER = checkMember(BUREAU.TRESORIER);
  const SECRETAIRE_GENERALE = checkMember(BUREAU.SECRETAIRE_GENERALE);
  const SECRETAIRE_ADJOINT = checkMember(BUREAU.SECRETAIRE_ADJOINT);

  const CONSEIL_ADMNINISTRATION = config.CONSEIL_ADMNINISTRATION ? config.CONSEIL_ADMNINISTRATION : {};

  return (
    <div className='mt-3'>
      <h2 className='mb-2'>Conseil d'administration USM Montargis Plongée :</h2>

      <div className='row my-3'>
        <div className="col justify-content-md-center">
          <img src={scubaGroup} alt="scuba-group.jpg" className='img-fluid rounded' />
        </div>
      </div>

      <div className="row my-3">

        <div className="col-md-4">
          <h4>Bureau</h4>
          <dl>
            {buildBureauMemberRow(PRESIDENT)}
            {buildBureauMemberRow(VICE_PRESIDENT)}
            {buildBureauMemberRow(TRESORIER)}
            {buildBureauMemberRow(SECRETAIRE_GENERALE)}
            {buildBureauMemberRow(SECRETAIRE_ADJOINT)}
          </dl>
        </div>

        <div className="col-md-4">
          <h4>Conseil d'administration</h4>
          <dl>

            {CONSEIL_ADMNINISTRATION.length > 0 && CONSEIL_ADMNINISTRATION.map(commission => {

              return (
                <div key={uuidv4()}>
                  <dt>{commission.LIBELLE}</dt>

                  {buildLineOrListMember(commission.MEMBRES)}

                </div>
              )
            })}
          </dl>
        </div>
      </div>

    </div>
  )
}
