const INITIAL_STATE = {
    formateurs: []
}

function formateurReducer(state = INITIAL_STATE, action) {

    switch(action.type) {
        case 'LOAD_FORMATEUR': {

            return {
                ...state,
                formateurs:  action.payload
            }
        }
    }

    return state; 
}

export default formateurReducer; 

export const getFormateurs = () => dispatch => {

    const formateurDataBase = [
        {
            nom: 'Joyeux',
            prenom: 'Fabrice',
            fonction: 'Responsable E1',
            photo: '/photo/formateur/fabrice-joyeux.jpg',
            competences: [
                'MF2',
                'Survie milieu hostile',
                'Formateur Rifap'
            ]
        },
        {
            nom: 'Bellemain',
            prenom: 'Natacha',
            fonction: 'Responsable N3',
            photo: '/photo/formateur/natacha-bellemain.jpg',
            competences: [
                'MF1',
                'Bourreau natation',
                'Formateur Rifap'
            ]
        },
        {
            nom: 'Aubey',
            prenom: 'Hervé',
            fonction: 'Responsable Niveau 2',
            photo: '/photo/formateur/herve-aubey.jpg',
            competences: [
                'MF1',
                'Formateur Nitrox',
                'Formateur Rifap'
            ]
        }

    ]

    dispatch({
        type: 'LOAD_FORMATEUR',
        payload: formateurDataBase
    })
}