import { Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getConfig } from './redux/config/configReducer';

import './App.scss';
import NavBar from './Components/NavBar/NavBar';
import Footer from './Components/Footer/Footer';
import Blog from './Container/Blog/Blog';
import ArticleDetail from './Container/Blog/ArticleDetail/ArticleDetail';
import Activite from './Container/Activite/Activite';
import Plongee from './Container/Activite/Plongee/Plongee'
import Apnee from './Container/Activite/Apnee/Apnee'

import Formations from './Container/Formations/Formations';
import Bio from './Container/Formations/Bio/Bio';

import Contact from './Container/Contact/Contact';
import About from './Container/About/About';
import Bureau from './Container/About/Bureau/Bureau';
import Formateur from './Container/About/Formateur/Formateur';
import AddArticle from './Container/AddArticle/AddArticle';
import SuppArticle from './Container/HandleArticle/HandleArticle';
import Sorties from './Container/Sorties/Sorties';
import Carrieres from './Container/Sorties/Carrieres/Carrieres';
import Mer from './Container/Sorties/Mer/Mer';
import Voyages from './Container/Sorties/Voyages/Voyages';
import Calendrier from './Container/Calendrier/Calendrier';
import DisplayDoc from 'Components/DisplayDoc/DisplayDoc';

import reglement from 'Assets/documents/REGLEMENT_INTERIEUR_SEPTEMBRE_2022.pdf';
import statuts from 'Assets/documents/STATUTS_PLONGEE_Septembre_2021.pdf';

function App() {

  const { config } = useSelector(state => ({
    ...state.configReducer
  }));

  const { token } = useSelector(state => ({
    ...state.tokenReducer
  }));

  const dispatch = useDispatch();

  useEffect(() => {

    // if (config ) {
    dispatch(getConfig());
    //}

  }, []);

  return (
    <>
      <NavBar />

      {/* <div className="container">
        <div className="row">
          <div className="col">
            <a href="https://www.salon-de-la-plongee.com/fr/accueil.html" target="_blank">
              <img src="/photo/evenements/Banniere_Salon_2023_1350x260.jpg" alt="salon_de_la_plongee.jpg" className='img-fluid rounded ' />
            </a>
          </div>
        </div>
      </div> */}

      <Routes>
        <Route path='*' element={<Blog />} />
        <Route path='/' element={<Blog />} />
        <Route path='/activite' element={<Activite />} >
          <Route path='/activite/plongee' element={<Plongee />} />
          <Route path='/activite/apnee' element={<Apnee />} />
          <Route path="/activite" element={<Plongee to="/activite/plongee" replace />} />
        </Route>
        <Route path='/article/:id' element={<ArticleDetail />} />
        {token &&
          <Route path='/formations' element={<Formations />} >
            {/* <Route path='/activite/plongee' element={<Plongee />} /> */}
            {/* <Route path='/activite/apnee' element={<Apnee />} /> */}
            <Route path='/formations/bio' element={<Bio />} />
            <Route path="/formations" element={<Bio to="/formations/bio" replace />} />
          </Route>
        }
        <Route path='/sorties' element={<Sorties />} >
          <Route path='/sorties/carrieres' element={<Carrieres />} />
          <Route path='/sorties/mer' element={<Mer />} />
          <Route path='/sorties/voyages-club' element={<Voyages />} />
          <Route path="/sorties" element={<Carrieres to="/sorties/carrieres" replace />} />
        </Route>
        <Route path='/contact' element={<Contact />} />
        <Route path='/calendrier' element={<Calendrier />} />
        <Route path='/a-propos' element={<About />} >
          <Route path='/a-propos/bureau' element={<Bureau />} />
          <Route path='/a-propos/formateur' element={<Formateur />} />
          <Route path='/a-propos/reglement' element={<DisplayDoc file={ reglement } label="Règlement intérieur" title="Règlement intérieur :"/>} />
          <Route path='/a-propos/statuts' element={<DisplayDoc file={ statuts } label="Statuts" title="Statuts :"/>} />
          <Route path="/a-propos" element={<Formateur to="/a-propos/formateur" replace />} />
        </Route>
        {token && <Route path='/ajouter-article' element={<AddArticle />} />}
        {token && <Route path='/supprimer-article' element={<SuppArticle />} />}
      </Routes>


      <Footer />

    </>
  );
}

export default App;
