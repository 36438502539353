import React from 'react';
import './Footer.scss';
import { FaFacebookSquare, FaMailBulk } from 'react-icons/fa';
import { BsFacebook, BsTelephone, BsFillTelephoneFill, BsMapFill } from 'react-icons/bs';

export default function Footer() {

  const CONF = window?.confPub;
  const PAGES = CONF?.ROUTES?.PAGES;

  //TODO gérer les petits écrans
  return (
    <footer className='background-footer'>
      <div className="container">

        <div className="row pb-4">

          <div className="col-sm-6 col-md-3 d-flex flex-column">
            <h5>
              <a href={PAGES?.BLOG?.LINK}>
                {PAGES?.BLOG?.LABEL}
              </a>
            </h5>

            <h5>
              <a href={PAGES?.CALENDRIER?.LINK}>
                {PAGES?.CALENDRIER?.LABEL}
              </a>
            </h5>

            <h5>
              <a href={PAGES?.CONTACT?.LINK}>
                {PAGES?.CONTACT?.LABEL}
              </a>
            </h5>

            {/* <div className="d-flex flew-row contact-footer">
              <a target="_blank" href="https://www.facebook.com/USMMontargisPlongee/"><BsFacebook /></a>
              <a href="tel:0613880322"><BsFillTelephoneFill /></a>
              <a href="mailto:usmmplongee@gmail.com"><FaMailBulk /></a>
              <a target="_blank" href="https://www.google.com/maps/place/Piscine+du+Lac/@47.9934794,2.7376771,17z/data=!3m1!4b1!4m5!3m4!1s0x47ef80997f381759:0x784a21d5fab41e1a!8m2!3d47.9934758!4d2.7398658"><BsMapFill /></a>
            </div> */}
          </div>

          <div className="col-sm-6 col-md-3 d-flex flex-column">
            <h5>{PAGES?.ACTIVITES?.LABEL}</h5>
            <a href={PAGES?.ACTIVITES?.LINKS?.PLONGEE?.LINK}>
              {PAGES?.ACTIVITES?.LINKS?.PLONGEE?.LABEL}
            </a>
            <a href={PAGES?.ACTIVITES?.LINKS?.APNEE?.LINK}>
              {PAGES?.ACTIVITES?.LINKS?.APNEE?.LABEL}
            </a>
          </div>

          <div className="col-sm-6 col-md-3 d-flex flex-column">
            <h5>{PAGES?.SORTIES?.LABEL}</h5>
            <a href={PAGES?.SORTIES?.LINKS?.CARRIERES?.LINK}>
              {PAGES?.SORTIES?.LINKS?.CARRIERES?.LABEL}
            </a>
    

            {/* <div className="d-flex flew-row contact-footer">
              <a target="_blank" href="https://www.facebook.com/USMMontargisPlongee/"><BsFacebook /></a>
              <a href="tel:0613880322"><BsFillTelephoneFill /></a>
              <a href="mailto:usmmplongee@gmail.com"><FaMailBulk /></a>
              <a target="_blank" href="https://www.google.com/maps/place/Piscine+du+Lac/@47.9934794,2.7376771,17z/data=!3m1!4b1!4m5!3m4!1s0x47ef80997f381759:0x784a21d5fab41e1a!8m2!3d47.9934758!4d2.7398658"><BsMapFill /></a>
            </div> */}

          </div>

          <div className="col-sm-6 col-md-3 d-flex flex-column second-lvl-sm">
            <h5>{PAGES?.ABOUT?.LABEL}</h5>
            <a href={PAGES?.ABOUT?.LINKS?.BUREAU?.LINK}>
              {PAGES?.ABOUT?.LINKS?.BUREAU?.LABEL}
            </a>
            <a href={PAGES?.ABOUT?.LINKS?.FORMATEURS?.LINK}>
              {PAGES?.ABOUT?.LINKS?.FORMATEURS?.LABEL}
            </a>
            <a href={PAGES?.ABOUT?.LINKS?.REGLEMENT?.LINK}>
              {PAGES?.ABOUT?.LINKS?.REGLEMENT?.LABEL}
            </a>
            <a href={PAGES?.ABOUT?.LINKS?.STATUTS?.LINK}>
              {PAGES?.ABOUT?.LINKS?.STATUTS?.LABEL}
            </a>
          </div>

        </div>

        <hr />

        <div className='mt-3 d-flex justify-content-center'>

          <ul >
            <li className='inline'>
              <a href="#" className="liens">&copy; 2022 USM Montargis Plongée</a>
            </li>
            <li className='inline border-left'>
              <a href="#" className="liens">Accessibilité</a>
            </li>
            <li className='inline border-left'>
              <a href="#" className="liens">
                Plan du site
              </a>
            </li>
            <li className='inline border-left'>
              <a target="_blank" href="https://www.facebook.com/USMMontargisPlongee/"><BsFacebook /></a>
            </li>
            <li className='inline ps-3 ms-3'>
              <a href="tel:0613880322"><BsFillTelephoneFill /></a>
            </li>

            <li className='inline ps-3 ms-3'>
              <a href="mailto:usmmplongee@gmail.com"><FaMailBulk /></a>
            </li>
            <li className='inline ps-3 ms-3'>
              <a target="_blank" href="https://www.google.com/maps/place/Piscine+du+Lac/@47.9934794,2.7376771,17z/data=!3m1!4b1!4m5!3m4!1s0x47ef80997f381759:0x784a21d5fab41e1a!8m2!3d47.9934758!4d2.7398658"><BsMapFill /></a>
            </li>
          </ul>

        </div>

      </div>
    </footer>
  )
}
