import React from 'react';
import { Link, Outlet, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import logoFFESSM from '../../Assets/ffessm.jpg';
import './About.scss';

export default function About() {


  return (
    <div className='container mt-3'>

      <div className="row">
        <div className="col-md-8">
          <h1>À propos :</h1>
          <p>
            L’USM Montargis est une association loi 1901 affiliée à la Fédération Française d’Etudes et de Sports Sous-Marins (FFESSM) sous le numéro 27450220.
            <br />
            L’association est gérée par un Bureau et un Comité Directeur élus par les membres.</p>
        </div>

        <div className="col-md-4">
          <img className='logo-ffessm d-none d-md-block' src={logoFFESSM} alt="Logo FFESSM" />
        </div>
      </div>

      <ul className="nav nav-tabs">
        <li id='nav-formateur' className="nav-item">
          <NavLink

            to='/a-propos/formateur'
            className={({ isActive }) => {
              console.log('is active ? ' + isActive)
              return isActive ? 'nav-link active' : 'nav-link';
            }}
          >Formateurs</NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to='/a-propos/bureau'
            className='nav-link'
          >Membre du bureau</NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to='/a-propos/reglement'
            className='nav-link'
          >Règlement intérieur</NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to='/a-propos/statuts'
            className='nav-link'
          >Statuts</NavLink>
        </li>

      </ul>

      <Outlet />
    </div>
  )
}

// className={({isActive}) => {
//   console.log('is active ? ' + isActive)
//   return isActive ? 'nav-link active' : 'nav-link';
// }}
