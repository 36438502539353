import React, { useState } from 'react';
import unknowPicture from '../../../../Assets/unknow-picture.jpg';
import './FormateurCard.scss';
import { Button, Collapse } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

export default function FormateurCard(props) {

  const [open, setOpen] = useState(false);

  return (
    <div className='col-md-3 mb-3'>
      <div className="card shadow-sm formateur-min-height">
        <div className="card-body">
          <div className='center-img'>
            <img
              src={props.formateur.photo === null ? unknowPicture : props.formateur.photo}
              className='card-img-top photo-formateur rounded'
              alt="" />
          </div>
          <h4 className="mt-2 text-center">{props.formateur.prenom} {props.formateur.nom}</h4>
          <p className="mt-1 text-center">{props.formateur.fonction}</p>
          <div className='text-center'>
            <Button
              className='mb-2'
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}>
              Plus d'informations
            </Button>
          </div>


          <Collapse in={open}>
            <ul className="list-group list-group-flush">

              {props.formateur.competences.map(competence => {
                return (
                  <li className="list-group-item" key={uuidv4()}>{competence}</li>
                )
              })}

            </ul>
          </Collapse>


        </div>
      </div>

      {props.children}
    </div>
  )
}
