const INITIAL_STATE = {
    token: null
}

function tokenReducer(state = INITIAL_STATE, action) {

    switch (action.type) {
        case 'SET_TOKEN': {

            return {
                ...state,
                token: action.payload
            }
        }
    }

    return state;
}

export default tokenReducer;

export const setToken = () => dispatch => {

    const token = localStorage.getItem('token');

    dispatch({
        type: 'SET_TOKEN',
        payload: token
    })
}