import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import './Article.scss';
import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify'

import AddPhoto from './AddPhoto';
import { Link } from 'react-router-dom';

export default function Article() {

    const [articles, setArticles] = useState([]);

    const [show, setShow] = useState(false);
    const [photoUrl, setPhotoUrl] = useState('');
    const [photoTitle, setPhotoTitle] = useState('');
    const handleClose = () => {
        setShow(false);
    }
    
    const handleShow = (img, title) => {
        setPhotoUrl(img);
        setPhotoTitle(title)
        setShow(true);
    }

    useEffect(() => {

        axios.get('article/get-articles').then(res => {

            setArticles(res.data);
        }).catch(err => {
            console.error('requestArticles err', err)
        });
        
    }, []);

    console.log(articles)

    const addCredit = (credit) => {
        return (
            <div className="text-end" >
                <small >Photo : {credit}</small>
            </div >
        )
    }

    return (
        <div className='row articles justify-content-center'>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{photoTitle}</Modal.Title>
                </Modal.Header>
                <img src={photoUrl} className="img-fluid" />
            </Modal>

            {(articles && articles.length > 0) && articles.map(article => {

                console.log('article')
                console.log(article)

                return (
                    <div key={ article._id } className="col-12 col-lg-6 col-xxl-4 mt-4">
                        <div className="card shadow-sm h-100">

                            <Link
                                to={ '/article/' + article._id }
                                state={{article: article}}
                                >

                                    <AddPhoto key={article.mainPhoto._id} image={article.mainPhoto} />

                                    <div className="card-body pb-2">

                                        <p className='float-end fst-italic'>{article.date}</p>
                                        <h5 className="card-title">{article.title}</h5>
                                        <h6 className="card-subtitle mb-2 text-muted">{article.subTitle}</h6>
                                        <p className="card-text" dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(article.teaser) } }></p>

                                        { article.credit && addCredit(article.credit) }
                                        { article.id && addCredit(article._id) }

                                    </div>
                                </Link>
                        </div>
                    </div>
                );
            })}

            {/* 

            <div className=" col-12 col-md-6 col-xl-4 mt-3">
                <div className="card shadow-sm article-size" >
                    <img src="/photo/blog/beffes.JPG" className="card-img-top article-img" alt=""
                        onClick={() => handleShow('/photo/blog/beffes.JPG', 'Les profondeurs de Beffes')} />
                    <div className="card-body">
                        <p className='float-end fst-italic'>19 février 2022</p>
                        <h5 className="card-title">Les profondeurs de Beffes</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Exploration dans la pénombre</h6>
                        <p className="card-text">Une eau au alentours de 7 °C, une visibilitée qui fait perdre tout son sens au mot,
                            oui vous êtes à Beffes en plein mois de février.</p>
                        <div className="text-end">
                            <small >Photo : Thibaut Dupin</small>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
