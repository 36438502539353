import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';


export default function DisplayDoc({ file, label, title }) {

    const displayFile = (`${file}#toolbar=1&navpanes=0&scrollbar=1&pagemode=none`);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
    const goToNextPage = () => setPageNumber(
        pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
    );

    return (
        <div className='mt-3'>
            <h2>{title}</h2>

            {/* {'PDF Viewer' in navigator.plugins ? ( */}
                <object id="affichage-ba"
                    type="application/pdf"
                    width="100%"
                    height="1000"
                    data={displayFile} />
            {/* ) : (
                <>
                    <div>
                        <Document file={file} onLoadSuccess={onDocumentLoadSuccess} >
                            <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}/>
                        </Document>
                    </div>
                    <div className='my-3'>
                        <button className='btn btn-primary'
                            onClick={goToPrevPage}>
                            Précédent
                        </button>
                        <button className='btn btn-primary ms-3'
                            onClick={goToNextPage}>
                            Suivant
                        </button>
                        <div className="float-end">
                            <p>Page {pageNumber} of {numPages}</p>
                        </div>
                    </div>
                </>
            )} */}

            <div>
                <span>Télécharger le document :&nbsp;<a className='mt-2'
                    href={displayFile}
                    download
                    target='_blank'
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(label)
                    }} />
                </span>
            </div>
        </div>
    )
}
