import React from 'react';
import './Plongee.scss';
import photoCoursPlongee from '../../../Assets/activite/cours-plongee-1.jpg';

export default function () {
    return (
        <div className='container mt-3'>

            <h1 className='mb-3'>Plongée sous-marine avec scaphandre</h1>
            <div className="row mb-3">
                <div className="col-xl-8 div-Plongee">
                    <img src="/photo/plongee/photo-plongee-1.jpg" alt="photo-plongee.jpg" className='img-fluid rounded ' />
                    <div className="text-end">
                        <small >Photo : Sébastien Huertas</small>
                    </div>
                </div>

                <div className="col-xl-4">
                    <h2>À la découverte des profondeurs</h2>
                    <p>La plongée sous-marine consiste à s'équiper d'un "scaphandre" composé
                        d'une bouteille de plongée, d'un gilet de stabilisation et d'un détendeur alimentant le plongeur en air.
                        Cette activité, pratiqué en tant que loisir, permet de progresser à des profondeurs comprises entre
                        la surface et 60 m et de partir à la découverte des fonds marins.</p>

                    <p>Que ce soi pour observer la très grande variété d'espèces animales
                        et végétales du monde subaquatique ou pour explorer des grottes et des épaves, la plongée a énormément de
                        choses à offrir le tout en apesanteur en progressant en trois dimensions.</p>

                    <p>Elle se pratique partout, en mer, océans, lac, rivières, barrage et en toutes saisons,
                        pour les plus courageux.</p>

                </div>
            </div>
            <div className="row mt-3">
                <figure className="text-end">
                    <blockquote className="blockquote">
                        <p>Les gens fuient par divers moyens : certains prennent un verre, ou deux bouteilles, ou une bouteille de plongée ...</p>
                    </blockquote>
                    <figcaption className="blockquote-footer">
                        Luc Besson <cite title="Source Title">- Cannes - 8 Mai 1997</cite>
                    </figcaption>
                </figure>
            </div>
            <div className='row'>

                <div className="col-xl-4">
                    <h2>Formations :</h2>
                    <h3>Devenir plongeur :</h3>
                    <p>
                        Afin de profiter pleinement de la plongée avec bouteille, il est nécessaire de se former.
                        Afin de gagner en aisance, en autonomie et en sécurité pour se faire plaisir.
                        Les différents niveaux de plongée permettent d'acquérir des prérogatives allant du plongeur encadré
                        (PE20 pour plongée encadrée jusqu'à 20 mètres) au plongeur autonome (PA20 pour plongée autonome jusqu'à 20 mètres).
                    </p>
                    <ul>
                        <li>Niveau 1 : PE20.</li>
                        <li>Niveau 2 : PA20 & PE40.</li>
                        <li>Niveau 3 : PA40 & PA60.</li>
                        <li>Niveau 4 : Guide de palanquée.</li>
                        <li>Niveau 5 : Directeur de plongée en exploration.</li>
                    </ul>
                    <p>
                        Ces différents niveaux peuvent être travaillés en piscine tout au long de l'année. Des sorties en milieux naturels
                        permettent de les valider.
                    </p>
                    <p>L'USMM plongée propose de former en piscine du niveau 1 à 4 et de réaliser des sorties en carrières, lacs, mers, océans.</p>
                </div>

                <div className="col-xl-8 div-Plongee">
                    <img src={ photoCoursPlongee } />
                    <div className="text-end">
                        <small >Photo : Guillaume Huertas</small>
                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col">
                    <h3>Enseigner la plongée :</h3>
                    <p>
                        À partir du niveau 2, il est possible de se diriger vers l'enseignement de la plongée. Différents
                        niveaux d'enseignement existent :
                    </p>
                    <ul>
                        <li>E1 : enseignement jusqu'à 6 mètres  et directeur de bassin, à partir du niveau 2.</li>
                        <li>E2 : enseignement jusqu'à 20 mètres et directeur de bassin, à partir du niveau 2.</li>
                        <li>E3 : enseignement jusqu'à 40 mètres et directeur de plongée (DP) en milieu naturel.</li>
                        <li>E4 : enseignment jusqu'à 60 mètre et directeur de plongée (DP) en milieu naturel.</li>
                    </ul>
                    <p>Chez nous vous pourrez être formés jusqu'au E2.</p>
                </div>
            </div>
        </div>
    )
} 
