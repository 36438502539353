import React, { useState, useEffect } from 'react';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import './Bio.scss';
import anemone_bijoux from '../../../Assets/cours/bio/anemone_bijoux.jpg'
import araignee from '../../../Assets/cours/bio/araignee.jpg'
import poulpe_commun from '../../../Assets/cours/bio/poulpe_commun.jpg'
import nudibranche from '../../../Assets/cours/bio/nudibranche.jpg'
import nudibranche2 from '../../../Assets/cours/bio/nudibranche-2.jpg'
import nudibranche3 from '../../../Assets/cours/bio/nudibranche-3.jpg'
import TreeMenu from 'react-simple-tree-menu';
import '../../../../node_modules/react-simple-tree-menu/dist/main.css';

export default function Bio() {

  const [gallery, setGallery] = useState();

  useEffect(() => {

    setGallery([
      {
        legend: 'Poulpe commun',
        photo: poulpe_commun
      },
      {
        legend: 'Anémone bijoux',
        photo: anemone_bijoux
      },
      {
        legend: 'Araignée',
        photo: araignee
      },
    ])

  }, []);

  const nudibranchePhotos = [
    {
      legend: 'nudibranche',
      photo: nudibranche
    },
    {
      legend: 'nudibranche2',
      photo: nudibranche2
    },
    {
      legend: 'nudibranche3',
      photo: nudibranche3
    }
  ];

  const cephalopodes = [
    {
      legend: 'Poulpe commun',
      photo: poulpe_commun
    }
  ];

  const treeData = [
    {
      key: 'vegetaux',
      label: 'Végétaux',
      // any other props you need, e.g. url
      nodes: [
        {
          key: 'algues',
          label: 'Algues',
        },
        {
          key: 'plantes',
          label: 'Plantes',
        },
      ],
    },
    {
      key: 'animaux',
      label: 'Animaux',
      nodes: [
        {
          key: 'spongiaires',
          label: 'Spongiaires'
        },
        {
          key: 'cnidaires',
          label: 'Cnidaires'
        },
        {
          key: 'bryozoaires',
          label: 'Bryozoaires'
        },
        {
          key: 'vers',
          label: 'Vers'
        },
        {
          key: 'arthropodes',
          label: 'Arthropodes'
        },
        {
          key: 'mollusques',
          label: 'Mollusques',
          nodes: [
            {
              key: 'gasteropodes',
              label: 'Gastéropodes',
              photos: nudibranchePhotos
            },
            {
              key: 'bivalves',
              label: 'Bivalves'
            },
            {
              key: 'cephalopodes',
              label: 'Céphalopodes',
              photos: cephalopodes
            }
          ]
        },
        {
          key: 'echinodermes',
          label: 'Echinodermes'
        },
        {
          key: 'ascidies',
          label: 'Ascidies'
        },
        {
          key: 'vertebres',
          label: 'Vertébrés'
        },


      ]
    },
  ]

  const alimGallery = (photos) => {

    if (photos) {
      setGallery(photos)
    }
  }

  return (
    <div className='container -mt-3'>
      <h1>La Bio en plongée</h1>
      <p>
        La Biologie ou Bio en plongée consiste à étudier les différentes espèces que nous sommes amener à cotoyer
        au cours de nos pérégrinations. Il est en effet assez frustrant de descendre dans le bleu de voir cette prolifération
        de vie et une fois à bord d'être incapable de dire à ses compagnons ce qu'on a vu.
        L'étude ludique du milieu subaquatique nous permet d'apprécier d'avantage chaque descente et chaque recontre
        avec les habitants de ce milieu.
      </p>
      <h2>Pour reconnaître une espèce il faut commencer par la classer</h2>
      <p>
        Il n'est pas toujours évident de reconnaître une espèce au premier coup d'oeil, la première étape consiste à la
        classer pour savoir de qu'elle "famille" elle fait partie.
        Nous vous proposons ici de vous présenter les grandes "familles" que l'on nomme embranchement afin d'y voir plus clair.
      </p>

      <div className="row">

        <div className="col-lg-3">
          <TreeMenu
            data={treeData}
            onClickItem={({ key, label, photos, ...props }) => {
              //this.navigate(props.url); // user defined prop
              alimGallery(photos);
            }}
          ></TreeMenu>
        </div>

        <div className="col-lg-9">
          {/* <Carousel autoPlay='true' infiniteLoop='true'>

            {gallery && gallery.length > 0 && gallery.map(photo => {

              return (
                <div>
                  <img src={photo.photo} />
                  <p className="legend">{photo.legend}</p>
                </div>
              )
            })}
          </Carousel> */}
        </div>

      </div>

      <div className='activite-footer-spacer'></div>
    </div>
  )
}
